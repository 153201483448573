import { useEffect } from "react";

const useScrollAnimation = (selector) => {
  useEffect(() => {
    const elements = document.querySelectorAll(selector);

    const checkScroll = () => {
      elements.forEach((element) => {
        const rect = element.getBoundingClientRect();
        if (rect.top < window.innerHeight - 100) {
          element.classList.add("visible");
        } else {
          element.classList.remove("visible");
        }
      });
    };

    // Run on mount and scroll
    checkScroll();
    window.addEventListener("scroll", checkScroll);
    
    return () => window.removeEventListener("scroll", checkScroll);
  }, [selector]);
};

export default useScrollAnimation;
