

import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Paper } from "@mui/material";
import missMastersLogo from '../../assets/images/product/missMastersLogo.png'
import FaurootLogo from '../../assets/images/product/faurootsLogo.png'
import FDSLogo from '../../assets/images/product/fdsLogo.png'
import IgoloLogo from '../../assets/images/product/igoloLogo.png'
import SeylanLogo from '../../assets/images/product/seylanLogo.png'
import NawalankaLogo from '../../assets/images/product/nawaLankaLogo.png'
import DoneRightLogo from '../../assets/images/product/doneRightLogo.png'
import CareLogo from '../../assets/images/product/careLogo.png'
import MistyFloraLogo from '../../assets/images/product/mistyFloraLogo.png'

import Styles from "./clientComp.module.css";


export default function ClientComp() {


    return (
        <>
            <div className={Styles.clientCompMainOuter}>
                <div className={Styles.clientBackgroundStatic}>
                    <div className={Styles.clientCompConten}>
                        <Box className={Styles.clientHeaderBox}>
                            <Typography variant='h3' className={Styles.clientHeaderTxt}>Our Valued Partners</Typography>
                            <Typography variant="h6" className={Styles.clientBodyTxt}>Trusted by industry leaders and forward-thinking companies worldwide, N-ACH has collaborated with clients from various sectors, to create transformative software solutions that drive measurable success.</Typography>
                        </Box>
                        <Box className={Styles.clientCardMainSwiper}>
                            <Paper className={Styles.clientPaper1}>
                                <div className={Styles.clientLogoCont}>
                                    <img src={missMastersLogo} className={Styles.clientLogo} />
                                    <img src={FaurootLogo} className={Styles.clientLogo} />
                                    <img src={FDSLogo} className={Styles.clientLogo} />
                                </div>

                                <Typography variant="h4" className={Styles.clientCountryText}>UAE</Typography>
                            </Paper>
                            <Paper className={Styles.clientPaper2}>
                                <div className={Styles.clientLogoCont}>
                                    <img src={IgoloLogo} className={Styles.clientLogo} />
                                    <img src={SeylanLogo} className={Styles.clientLogo} />
                                    <img src={NawalankaLogo} className={Styles.clientLogo} />
                                </div>

                                <Typography variant="h4" className={Styles.clientCountryText}>Sri Lanka</Typography>
                            </Paper>
                            <Paper className={Styles.clientPaper3}>
                                <div className={Styles.clientLogoCont}>
                                    <img src={DoneRightLogo} className={Styles.clientLogo} />
                                    <img src={CareLogo} className={Styles.clientLogoSqr} />
                                    <img src={MistyFloraLogo} className={Styles.clientLogo} />
                                </div>

                                <Typography variant="h4" className={Styles.clientCountryText}>Australia</Typography>
                            </Paper>

                        </Box>
                    </div>

                </div>
            </div>
        </>

    );
}

