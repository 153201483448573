// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ourStory_outerContainer__\\+z4h5 {
    padding: 120px;
    background: linear-gradient(180deg, #f4f9fc 0%, #99b2b7 100%);
}

.ourStory_topic__tK6\\+G {
    font-size: 3rem;
    font-style: normal;
    font-weight: 600;
    text-align: left;
    margin: 0;
}

.ourStory_description__BxH0r {
    width: 100%;
    font-size: 18px;
    text-align: left;
    margin-top: 2.5rem;
    margin-right: 8%;
}

.ourStory_imageGrid__nv9g9 img {
    max-width: 100%; 
    height: auto; 
    margin-left: 4%;
    margin-top: 4%;
}
@media (max-width: 770px) {
    .ourStory_description__BxH0r {
        width: 100%;
        margin-left: 0; 
    }
    .ourStory_outerContainer__\\+z4h5 {
        padding: 50px;
    }
    .ourStory_description__BxH0r {
        margin-top: 0px;
        margin-right: 0px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/home/ourStory.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,6DAA6D;AACjE;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;IAChB,SAAS;AACb;;AAEA;IACI,WAAW;IACX,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,eAAe;IACf,cAAc;AAClB;AACA;IACI;QACI,WAAW;QACX,cAAc;IAClB;IACA;QACI,aAAa;IACjB;IACA;QACI,eAAe;QACf,iBAAiB;IACrB;AACJ","sourcesContent":[".outerContainer {\r\n    padding: 120px;\r\n    background: linear-gradient(180deg, #f4f9fc 0%, #99b2b7 100%);\r\n}\r\n\r\n.topic {\r\n    font-size: 3rem;\r\n    font-style: normal;\r\n    font-weight: 600;\r\n    text-align: left;\r\n    margin: 0;\r\n}\r\n\r\n.description {\r\n    width: 100%;\r\n    font-size: 18px;\r\n    text-align: left;\r\n    margin-top: 2.5rem;\r\n    margin-right: 8%;\r\n}\r\n\r\n.imageGrid img {\r\n    max-width: 100%; \r\n    height: auto; \r\n    margin-left: 4%;\r\n    margin-top: 4%;\r\n}\r\n@media (max-width: 770px) {\r\n    .description {\r\n        width: 100%;\r\n        margin-left: 0; \r\n    }\r\n    .outerContainer {\r\n        padding: 50px;\r\n    }\r\n    .description {\r\n        margin-top: 0px;\r\n        margin-right: 0px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"outerContainer": `ourStory_outerContainer__+z4h5`,
	"topic": `ourStory_topic__tK6+G`,
	"description": `ourStory_description__BxH0r`,
	"imageGrid": `ourStory_imageGrid__nv9g9`
};
export default ___CSS_LOADER_EXPORT___;
