// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.waveBackground_waveOuter__NPUsS {
    margin: 0;
    /* background-color: #143754; */
    padding: 0px;
    margin: 0px;
  }
  
  .waveBackground_waveWrapper__LWOzN {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

  }
  
  .waveBackground_waveContainer__ZnTu\\+ {
    width: 100vw;
    height: 75vh;
    position: relative;

  }
  
  .waveBackground_waveContainer__ZnTu\\+ svg {
    position: absolute;
    top: 50;
    left: 0;
    width: 100%;

  }
  .waveBackground_waveOuter__NPUsS .waveBackground_waveWrapper__LWOzN {
    max-width: 100% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

`, "",{"version":3,"sources":["webpack://./src/components/footer/waveBackground.module.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,+BAA+B;IAC/B,YAAY;IACZ,WAAW;EACb;;EAEA;IACE,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;;EAElC;;EAEA;IACE,YAAY;IACZ,YAAY;IACZ,kBAAkB;;EAEpB;;EAEA;IACE,kBAAkB;IAClB,OAAO;IACP,OAAO;IACP,WAAW;;EAEb;EACA;IACE,0BAA0B;IAC1B,0BAA0B;IAC1B,2BAA2B;IAC3B,yBAAyB;IACzB,0BAA0B;AAC9B","sourcesContent":[".waveOuter {\r\n    margin: 0;\r\n    /* background-color: #143754; */\r\n    padding: 0px;\r\n    margin: 0px;\r\n  }\r\n  \r\n  .waveWrapper {\r\n    position: absolute;\r\n    top: 50%;\r\n    left: 50%;\r\n    transform: translate(-50%, -50%);\r\n\r\n  }\r\n  \r\n  .waveContainer {\r\n    width: 100vw;\r\n    height: 75vh;\r\n    position: relative;\r\n\r\n  }\r\n  \r\n  .waveContainer svg {\r\n    position: absolute;\r\n    top: 50;\r\n    left: 0;\r\n    width: 100%;\r\n\r\n  }\r\n  .waveOuter .waveWrapper {\r\n    max-width: 100% !important;\r\n    padding-left: 0 !important;\r\n    padding-right: 0 !important;\r\n    margin-left: 0 !important;\r\n    margin-right: 0 !important;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"waveOuter": `waveBackground_waveOuter__NPUsS`,
	"waveWrapper": `waveBackground_waveWrapper__LWOzN`,
	"waveContainer": `waveBackground_waveContainer__ZnTu+`
};
export default ___CSS_LOADER_EXPORT___;
