// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WhyChooseUs_outerContainer__Vz5jD{
    margin-top: 100px;
    margin-bottom: 100px;
}
.WhyChooseUs_topic__A8IhC {
    font-size: 3rem;
    font-style: normal;
    font-weight: 600;
    text-align: left;
    margin: 0;
}

.WhyChooseUs_description__ej6Fa {
    width: 100%;
    font-size: 18px;
    text-align: left;
    margin-top: 2.5rem;
    margin-bottom: 2rem;
    margin-right: 8%;
}

.WhyChooseUs_imageGrid__NuAfY img {
    max-width: 100%; 
    height: auto; 
    margin-top: 4%;
}
.WhyChooseUs_ChooseUsContent__ElIJA{
    margin: 50px;
}
@media (max-width: 770px) {
    .WhyChooseUs_description__ej6Fa {
        width: 100%;
        margin-left: 0; 
        margin-top: 0px;
        margin-right: 0px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/home/WhyChooseUs.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,oBAAoB;AACxB;AACA;IACI,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;IAChB,SAAS;AACb;;AAEA;IACI,WAAW;IACX,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,cAAc;AAClB;AACA;IACI,YAAY;AAChB;AACA;IACI;QACI,WAAW;QACX,cAAc;QACd,eAAe;QACf,iBAAiB;IACrB;AACJ","sourcesContent":[".outerContainer{\r\n    margin-top: 100px;\r\n    margin-bottom: 100px;\r\n}\r\n.topic {\r\n    font-size: 3rem;\r\n    font-style: normal;\r\n    font-weight: 600;\r\n    text-align: left;\r\n    margin: 0;\r\n}\r\n\r\n.description {\r\n    width: 100%;\r\n    font-size: 18px;\r\n    text-align: left;\r\n    margin-top: 2.5rem;\r\n    margin-bottom: 2rem;\r\n    margin-right: 8%;\r\n}\r\n\r\n.imageGrid img {\r\n    max-width: 100%; \r\n    height: auto; \r\n    margin-top: 4%;\r\n}\r\n.ChooseUsContent{\r\n    margin: 50px;\r\n}\r\n@media (max-width: 770px) {\r\n    .description {\r\n        width: 100%;\r\n        margin-left: 0; \r\n        margin-top: 0px;\r\n        margin-right: 0px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"outerContainer": `WhyChooseUs_outerContainer__Vz5jD`,
	"topic": `WhyChooseUs_topic__A8IhC`,
	"description": `WhyChooseUs_description__ej6Fa`,
	"imageGrid": `WhyChooseUs_imageGrid__NuAfY`,
	"ChooseUsContent": `WhyChooseUs_ChooseUsContent__ElIJA`
};
export default ___CSS_LOADER_EXPORT___;
