import * as React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import Styles from "./ourProducts.module.css";
import image1 from "../../assets/images/product/1.png";
import image2 from "../../assets/images/product/2.png";
import image3 from "../../assets/images/product/4.png";
import image4 from "../../assets/images/product/5.png";
import image5 from "../../assets/images/product/7.png";
import image6 from "../../assets/images/product/8.png";
import image7 from "../../assets/images/product/9.png";
import image8 from "../../assets/images/product/10.png";
import image9 from "../../assets/images/product/11.png";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import DemoBtnDialog from '../../components/contact/demoBtnDialog';
import Box from '@mui/material/Box';

import { useNavigate } from 'react-router-dom';

const products = [
    {
        image: image4,
        subtopic: "Smart Biz",
        link: "/ecom",
        text: "Shopping meets convenience and variety",
        descrip: "Transform your shopping experience with our cutting-edge e-commerce platform—effortless, diverse, and tailored to your every need!",
        gradient: "linear-gradient(90deg, #0D72CD 0%, #1CA9C9 100%)"
    },
    {
        image: image5,
        subtopic: "Ecommerce Lite",
        link: "/ecomlite",
        text: "Stunning website with a E-Commerce",
        descrip: "Experience effortless shopping with our cutting-edge e-commerce platform—offering a vast range of products tailored to your every need!",
        gradient: "linear-gradient(90deg, #074074 0%, #0D72CD 100%)"
    },
    {
        image: image3,
        subtopic: "CRM",
        link: "/crm",
        text: "Advanced Customer Relationship Management System",
        descrip: "Boost customer relationships with N-ACH CRM—an innovative, intuitive platform designed to streamline interactions and drive business growth!",
        gradient: "linear-gradient(90deg, #0D72CD 0%, #0F52BA 100%)"
    },
    {
        image: image2,
        subtopic: "Inventory and Monitoring",
        link: "/stock",
        text: "Manage  your stocks easily",
        descrip: "Optimize inventory management with our advanced Stock Monitoring System—track, manage, and streamline operations effortlessly!",
        gradient: "linear-gradient(90deg, #20A4F3 0%, #182B3A 100%)"
    },
    {
        image: image1,
        subtopic: "HRM",
        link: "/hr",
        text: "Advanced human resouce system with 360 view of your staff",
        descrip: "Revolutionize HR management with our smart, user-friendly system—streamline processes, boost productivity, and foster a thriving workplace culture!",
        gradient: "linear-gradient(90deg, #0D0A0B 0%, #009FC2 100%)"
    },
    {
        image: image6,
        subtopic: "Payroll",
        link: "/payroll",
        text: "User friendly payment system your staff",
        descrip: "Effortlessly manage payroll with our secure, all-in-one Online Payroll System—streamline HR processes and ensure seamless salary management!",
        gradient: "linear-gradient(90deg, #4B0082 0%, #0892D0 100%)"
    },
    {
        image: image7,
        subtopic: "Issue Management",
        link: "/ims",
        text: "Cutting-edge Issue Ianagement System",
        descrip: "Optimize issue tracking with our smart Issue Management System—streamline resolutions, boost efficiency, and enhance customer satisfaction effortlessly!",
        gradient: "linear-gradient(90deg, #4B0082 0%, #0892D0 100%)"
    },
    {
        image: image8,
        subtopic: "EService",
        link: "/eservice",
        text: "One system for various issues, complaints and suggestions",
        descrip: "Embrace digital transformation with N-ACH E-Services System—streamline processes, boost efficiency, and simplify business operations effortlessly!",
        gradient: "linear-gradient(90deg, #170E13 0%, #7A7ADB 100%)"
    },
    {
        image: image9,
        subtopic: "Point of Sale",
        link: "/pos",
        text: "Advance online point of sale for your shop",
        descrip: "Transform your transactions with N-ACH Online POS System—intelligent, reliable, and efficient for seamless business management!",
        gradient: "linear-gradient(90deg, #090947 0%, #5A585A 100%)"
    },
];

function OurProducts() {

    const navigate = useNavigate()

    return (
        <div className={Styles.ourProductStaticBg}>
            <div className={`${Styles.outerContainer}`}>
                <Typography className={Styles.topic}>Our Products</Typography>
                <Typography className={Styles.description} >Our advanced digital solutions cater to various business needs. The E-Commerce Web offers a seamless shopping experience. The HR Management System enhances productivity, while the Issue Management System streamlines issue resolution. The CRM System improves customer interactions, and the Payroll System simplifies payroll management. Additionally, our E-Services, Stock Monitoring, and POS Systems optimize operations and transactions efficiently.</Typography>
                <Grid container spacing={3} justifyContent="left">
                    {products.map((product, index) => (

                        <Grid item xs={12} sm={6} md={4} key={index}>

                            <Card sx={{ maxWidth: 345, borderRadius: 3, height: 300 }}>
                                <Box
                                    sx={{
                                        paddingLeft: 2,
                                        height: 120,
                                        backgroundImage: product.gradient,

                                    }}
                                >
                                    <img src={product.image} />
                                </Box>

                                <CardContent>
                                    <Typography gutterBottom variant="h6" component="div" sx={{ fontFamily: "Albert Sans", fontWeight: 600 }}>
                                        {product.subtopic}
                                    </Typography>
                                    <Box className={Styles.productCardDescription}>
                                        <Typography variant="body1" sx={{ color: 'text.secondary', fontFamily: "Albert Sans", fontWeight: 400 }}>
                                            {product.text}
                                        </Typography>
                                    </Box>

                                </CardContent>
                                <CardActions className={Styles.cardActionBtnGroup}>
                                    <Button size="small" onClick={() => navigate(product.link)}>More Info</Button>

                                    <DemoBtnDialog prop={{ text: product.text, descript: product.descrip }} />

                                </CardActions>
                            </Card>

                        </Grid>
                    ))}
                </Grid>

            </div>
        </div>

    );
}

export default OurProducts;
