import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: "#459EFF",
      contrastText: "#fff"
    },
    // secondary: {
      // main: '#6667ab',
      // contrastText: "#fff"
    // },
    text: {
      // primary: "#459EFF",
      primary: "#414141",
    }
  },
  typography: {
    fontFamily: ['Livvic',"Albert Sans"],
    button: {
      textTransform: "none"
    }
  },
});

export default theme;