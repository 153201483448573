import React from 'react';
import { Paper, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import image from "../../assets/images/product/ceo.png";
import bodyImage from "../../assets/images/product/Group01.png";
import VisionMission from "./vision";
import styles from "../../pages/aboutUs.module.css";


const AboutPageBody = () => {
    return (
        <div className={styles.container}>
            <div className={styles.ceoMainOutercontainer}>
                <div className={styles.ceoMsgcontainer}>
                    <div>
                        <Typography variant='h5' className={styles.ceoMsgFirsttxt} >Technology Hub</Typography>
                        <Typography variant='h4' className={styles.aboutHeadertxt}>N-ACH</Typography>
                        <div className={styles.titelUnderLine}></div>
                        <Typography variant='body1' className={styles.ceoMsgBodytxt}> At N-ACH, we are a team of highly motivated and skilled professionals dedicated in supporting small businesses. With a deep understanding of entrepreneurial challenges, we help you navigate your journey with expert guidance tailored to your needs.<br></br>
                            Our services include strategic marketing and sales support, business function optimization, and personalized consulting to enhance your overall business operations. Whether you're launching a startup or scaling your business, we are here to empower you with the right strategies for success.
                            Let’s build a smarter, more efficient future for your businesstogether!
                        </Typography>
                    </div>
                    <div className={styles.ceoAvaterContainer}>
                        <Avatar
                            alt="Remy Sharp"
                            src={image}
                            className={styles.aboutAvatar}
                            sx={{ width: 64, height: 64, marginRight: 3 }}
                        />
                        <div>
                            <Typography variant='h6' className={styles.nameTag1}>Ms. Chathuri Ketipearachchi</Typography>
                            <Typography variant='subtitle1' className={styles.nameTag2}>Chief Executive Officer</Typography>
                        </div>

                    </div>
                </div>
                <div className={styles.ceoMainContainerBg}></div>
            </div>
<div>
    <VisionMission />
</div>
            <div className={styles.aboutSecondContainer}>
                <div className={styles.aboutBodyContantOuter}>
                    <div>
                        <Typography variant='h5' className={styles.ceoMsgFirsttxt} >Excellence</Typography>
                        <Typography variant='h4' className={styles.aboutHeadertxt}>Scalable and innovative
                            for your success</Typography>
                        <div className={styles.titelUnderLine}></div>
                        <img src={bodyImage} className={styles.AboutBodyImage} />
                    </div>
                    <div className={styles.secondeContainerPaper}>
                        <Paper className={styles.contentPaper}>
                            <Typography variant='h5' className={styles.aboutHeadertxt}>Passion for learning</Typography>
                            <div className={styles.titelUnderLine}></div>
                            <Typography variant='body1' className={styles.ceoMsgBodytxt} >We are passionate about continuous learning, because drives everything we do. We constantly refine our skills in design, development, and marketing while embracing new technologies. By sharing knowledge, we innovate, evolve, and stay ahead, because lifelong learning fuels success.</Typography>
                        </Paper>
                        <Paper className={styles.contentPaper}>
                            <Typography variant='h5' className={styles.aboutHeadertxt}>Driven by Curiosity</Typography>
                            <div className={styles.titelUnderLine}></div>
                            <Typography variant='body1' className={styles.ceoMsgBodytxt} >We believe learning is a way of life which blur the lines between work and play. Fueled by curiosity and creativity. Growth happens outside the comfort zone, and we embrace every challenge as an opportunity to innovate and evolve.</Typography>
                        </Paper>
                        <Paper className={styles.contentPaper}>
                            <Typography variant='h5' className={styles.aboutHeadertxt}>Collaboration Over Ego</Typography>
                            <div className={styles.titelUnderLine}></div>
                            <Typography variant='body1' className={styles.ceoMsgBodytxt} >We leave egos at the door. We challenge ideas, give honest feedback, and focus on what truly matters for our valued clients. While we push boundaries, we do so with purpose, not for the sake of it. Progress drives us, and collaboration defines us.</Typography>
                        </Paper>
                        <Paper className={styles.contentPaper}>
                            <Typography variant='h5' className={styles.aboutHeadertxt}>Stronger Together</Typography>
                            <div className={styles.titelUnderLine}></div>
                            <Typography variant='body1' className={styles.ceoMsgBodytxt} >Collaboration is at our core. We build more than just solutions, we uplift each other. Every project is an opportunity to connect, collaborate, and celebrate the success of our valued clients and team. We are in this together, committed for the long haul.</Typography>
                        </Paper>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutPageBody;
