// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.contactBtnDialog_btnDialogButton__RIw-c{
    color: #ffffff;
    border-color: #ffffff;
}
.contactBtnDialog_btnDialogButton__RIw-c:hover{
    color: #0e6dc6;
}

.contactBtnDialog_dialogSendButton__-dlSq {
    padding: 10px 15px;
    font-size: 16px;
    border-radius: 5px;
    background-color: #5fa9e0;
    box-shadow: none;
}
.contactBtnDialog_btnMainDialogComp__Xv3OM{
    align-items: center;
    text-align: center;
}
.contactBtnDialog_contactDilaogImage__20jWg{
    width: 100px;
}

@media (max-width: 770px) {
    .contactBtnDialog_btnDialogButton__RIw-c{
        font-size: 8px;
        padding: 3px 10px;
    }
}


/* demo btn  */

.contactBtnDialog_demBtnDialogButton__8nL0O{
    color: #0e6dc6;
    border-color: #0e6dc6;
}
.contactBtnDialog_demBtnDialogButton__8nL0O:hover{
    color: #043766;
    border-color: #043766;
}

.contactBtnDialog_contactDilaogImageGif__KwyCQ{
    width: 200px;
}`, "",{"version":3,"sources":["webpack://./src/components/contact/contactBtnDialog.module.css"],"names":[],"mappings":";;AAEA;IACI,cAAc;IACd,qBAAqB;AACzB;AACA;IACI,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,kBAAkB;IAClB,yBAAyB;IACzB,gBAAgB;AACpB;AACA;IACI,mBAAmB;IACnB,kBAAkB;AACtB;AACA;IACI,YAAY;AAChB;;AAEA;IACI;QACI,cAAc;QACd,iBAAiB;IACrB;AACJ;;;AAGA,cAAc;;AAEd;IACI,cAAc;IACd,qBAAqB;AACzB;AACA;IACI,cAAc;IACd,qBAAqB;AACzB;;AAEA;IACI,YAAY;AAChB","sourcesContent":["\r\n\r\n.btnDialogButton{\r\n    color: #ffffff;\r\n    border-color: #ffffff;\r\n}\r\n.btnDialogButton:hover{\r\n    color: #0e6dc6;\r\n}\r\n\r\n.dialogSendButton {\r\n    padding: 10px 15px;\r\n    font-size: 16px;\r\n    border-radius: 5px;\r\n    background-color: #5fa9e0;\r\n    box-shadow: none;\r\n}\r\n.btnMainDialogComp{\r\n    align-items: center;\r\n    text-align: center;\r\n}\r\n.contactDilaogImage{\r\n    width: 100px;\r\n}\r\n\r\n@media (max-width: 770px) {\r\n    .btnDialogButton{\r\n        font-size: 8px;\r\n        padding: 3px 10px;\r\n    }\r\n}\r\n\r\n\r\n/* demo btn  */\r\n\r\n.demBtnDialogButton{\r\n    color: #0e6dc6;\r\n    border-color: #0e6dc6;\r\n}\r\n.demBtnDialogButton:hover{\r\n    color: #043766;\r\n    border-color: #043766;\r\n}\r\n\r\n.contactDilaogImageGif{\r\n    width: 200px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btnDialogButton": `contactBtnDialog_btnDialogButton__RIw-c`,
	"dialogSendButton": `contactBtnDialog_dialogSendButton__-dlSq`,
	"btnMainDialogComp": `contactBtnDialog_btnMainDialogComp__Xv3OM`,
	"contactDilaogImage": `contactBtnDialog_contactDilaogImage__20jWg`,
	"demBtnDialogButton": `contactBtnDialog_demBtnDialogButton__8nL0O`,
	"contactDilaogImageGif": `contactBtnDialog_contactDilaogImageGif__KwyCQ`
};
export default ___CSS_LOADER_EXPORT___;
