// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.weCanHelp_outerContainer__vW3pE {
    padding: 120px 120px 100px 120px;
    animation: weCanHelp_slideInFromBottom__6KbnV 1s ease-in-out;
    background: linear-gradient(180deg , #F5F8FB 0%, #D5DFE5 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.weCanHelp_topic__kGBOX {
    font-size: 3rem;
    font-style: normal;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
}
.weCanHelp_description__I140e{
    align-items: center;
    text-align: center;
    justify-content: center;
   }

   @media (max-width: 770px) {
    .weCanHelp_topic__kGBOX {
        font-size: 2.5rem;
        margin: 0;
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .weCanHelp_outerContainer__vW3pE {
        padding: 20px 10px 0px 10px;
    }
    .weCanHelp_accordionQuestion__yg0bI{
        font-size: 16px;
    }
    
}
`, "",{"version":3,"sources":["webpack://./src/components/home/weCanHelp.module.css"],"names":[],"mappings":";AACA;IACI,gCAAgC;IAChC,4DAA2C;IAC3C,8DAA8D;IAC9D,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;;AAEvB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;IACnB,aAAa;IACb,uBAAuB;AAC3B;AACA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,uBAAuB;GACxB;;GAEA;IACC;QACI,iBAAiB;QACjB,SAAS;QACT,gBAAgB;QAChB,mBAAmB;IACvB;IACA;QACI,2BAA2B;IAC/B;IACA;QACI,eAAe;IACnB;;AAEJ","sourcesContent":["\r\n.outerContainer {\r\n    padding: 120px 120px 100px 120px;\r\n    animation: slideInFromBottom 1s ease-in-out;\r\n    background: linear-gradient(180deg , #F5F8FB 0%, #D5DFE5 100%);\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n    \r\n}\r\n\r\n.topic {\r\n    font-size: 3rem;\r\n    font-style: normal;\r\n    font-weight: 600;\r\n    margin-top: 20px;\r\n    margin-bottom: 20px;\r\n    display: flex;\r\n    justify-content: center;\r\n}\r\n.description{\r\n    align-items: center;\r\n    text-align: center;\r\n    justify-content: center;\r\n   }\r\n\r\n   @media (max-width: 770px) {\r\n    .topic {\r\n        font-size: 2.5rem;\r\n        margin: 0;\r\n        margin-top: 50px;\r\n        margin-bottom: 50px;\r\n    }\r\n    .outerContainer {\r\n        padding: 20px 10px 0px 10px;\r\n    }\r\n    .accordionQuestion{\r\n        font-size: 16px;\r\n    }\r\n    \r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"outerContainer": `weCanHelp_outerContainer__vW3pE`,
	"slideInFromBottom": `weCanHelp_slideInFromBottom__6KbnV`,
	"topic": `weCanHelp_topic__kGBOX`,
	"description": `weCanHelp_description__I140e`,
	"accordionQuestion": `weCanHelp_accordionQuestion__yg0bI`
};
export default ___CSS_LOADER_EXPORT___;
